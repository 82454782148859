
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IldlJ3JlIHdvcmtpbmcgb24gaXQuIiwiV2UgY2FuJ3Qgc2hvdyB0aGlzIGRhdGEgcmlnaHQgbm93LiBXZSdyZSBkb2luZyBvdXIgYmVzdCB0byBmaXggdGhpcy4iOiJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiIsIlRyeSByZWZyZXNoaW5nIHRoZSBwYWdlIG9yIGNoZWNrIGJhY2sgbGF0ZXIuIjoiVHJ5IHJlZnJlc2hpbmcgdGhlIHBhZ2Ugb3IgY2hlY2sgYmFjayBsYXRlci4ifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IlvFtOG6veG6veG6vSfFmeG6veG6veG6vSDFtcO2w7bDtsWZxLfDrMOsw6zguIHguLXguYnEoyDDtsO2w7bguIHguLXguYkgw6zDrMOs4bmvLl0iLCJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiI6IlvFtOG6veG6veG6vSDDp8SDxIPEg+C4geC4teC5iSfhua8gxaHhuKnDtsO2w7bFtSDhua/huKnDrMOsw6zFoSDGjMSDxIPEg+G5r8SDxIPEgyDFmcOsw6zDrMSj4bip4bmvIOC4geC4teC5icO2w7bDtsW1LiDFtOG6veG6veG6vSfFmeG6veG6veG6vSDGjMO2w7bDtsOsw6zDrOC4geC4teC5icSjIMO2w7bDtseax5rHmsWZIMOf4bq94bq94bq9xaHhua8g4bmvw7bDtsO2IMaSw6zDrMOs6q2VIOG5r+G4qcOsw6zDrMWhLl0iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ilvhua7FmcO9w73DvSDFmeG6veG6veG6vcaSxZnhur3hur3hur3FoeG4qcOsw6zDrOC4geC4teC5icSjIOG5r+G4qeG6veG6veG6vSDGpcSDxIPEg8Sj4bq94bq94bq9IMO2w7bDtsWZIMOn4bip4bq94bq94bq9w6fEtyDDn8SDxIPEg8OnxLcgxprEg8SDxIPhua/hur3hur3hur3FmS5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSdyZSB3b3JraW5nIG9uIGl0LiI6IlvFtOG6veG6veG6vSfFmeG6veG6veG6vSDFtcO2w7bDtsWZxLfDrMOsw6zguIHguLXguYnEoyDDtsO2w7bguIHguLXguYkgw6zDrMOs4bmvLl0iLCJXZSBjYW4ndCBzaG93IHRoaXMgZGF0YSByaWdodCBub3cuIFdlJ3JlIGRvaW5nIG91ciBiZXN0IHRvIGZpeCB0aGlzLiI6IlvFtOG6veG6veG6vSDDp8SDxIPEg+C4geC4teC5iSfhua8gxaHhuKnDtsO2w7bFtSDhua/huKnDrMOsw6zFoSDGjMSDxIPEg+G5r8SDxIPEgyDFmcOsw6zDrMSj4bip4bmvIOC4geC4teC5icO2w7bDtsW1LiDFtOG6veG6veG6vSfFmeG6veG6veG6vSDGjMO2w7bDtsOsw6zDrOC4geC4teC5icSjIMO2w7bDtseax5rHmsWZIMOf4bq94bq94bq9xaHhua8g4bmvw7bDtsO2IMaSw6zDrMOs6q2VIOG5r+G4qcOsw6zDrMWhLl0iLCJUcnkgcmVmcmVzaGluZyB0aGUgcGFnZSBvciBjaGVjayBiYWNrIGxhdGVyLiI6Ilvhua7FmcO9w73DvSDFmeG6veG6veG6vcaSxZnhur3hur3hur3FoeG4qcOsw6zDrOC4geC4teC5icSjIOG5r+G4qeG6veG6veG6vSDGpcSDxIPEg8Sj4bq94bq94bq9IMO2w7bDtsWZIMOn4bip4bq94bq94bq9w6fEtyDDn8SDxIPEg8OnxLcgxprEg8SDxIPhua/hur3hur3hur3FmS5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    