const CONTENT_WIDTH = 960;
const PADDING_SIZE = 20;
const CHAR_WIDTH = 15;
const SPACE_SIZE = 20;

const getMaxChars = (subHeadings: string[]) => {
  const stats = subHeadings.map((subHeading: string) => subHeading.length);
  return stats.length > 0 ? Math.max(...stats) : 0;
};

const getRows = (columns: number, length: number) => {
  const width = Math.floor(
    (CONTENT_WIDTH - SPACE_SIZE * (columns - 1)) / columns,
  );
  const jobAdContent = width - PADDING_SIZE * 2;
  const numOfRow = Math.floor(jobAdContent / CHAR_WIDTH);
  return Math.ceil(length / numOfRow);
};

export { getMaxChars, getRows };
