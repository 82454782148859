import type {
  ImageBlockFragment,
  ImageBlockGroupFragment,
} from '@seek/cmsu-cms-connect';
import { Box, Stack } from 'braid-design-system';
import React from 'react';
import { Asset, AssetType } from '../Asset/Asset';
import { Heading } from '../Heading/Heading';
import { Paragraph } from '../Paragraph/Paragraph';
import { getBoxBackgroundProps } from '../PageComponent/helper';
import { ButtonLink } from '../ActionGroup/ButtonLink';
import { TextLink } from '../ActionGroup/TextLink';

type Props = Pick<
  ImageBlockFragment,
  'heading' | 'image' | 'paragraph' | 'cButtonLink' | 'cTextLink'
> &
  Pick<ImageBlockGroupFragment, 'imageAlignment' | 'blockBackground'>;

export const InlineImageBlock = ({
  imageAlignment,
  image,
  heading,
  paragraph,
  blockBackground,
  cButtonLink,
  cTextLink,
}: Props) => (
  <Box
    display="flex"
    padding={blockBackground ? 'medium' : 'none'}
    height="full"
    {...getBoxBackgroundProps(blockBackground)}
  >
    {image && (
      <Box
        display="flex"
        alignItems={imageAlignment || 'flexStart'}
        paddingRight="medium"
      >
        <Asset
          assetType={AssetType.DEFAULT_IMAGE}
          {...image}
          style={{ maxWidth: 'fit-content' }}
        />
      </Box>
    )}
    <Box
      width="full"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Stack space="small">
        {heading && <Heading {...heading} />}
        {paragraph && (
          <Paragraph
            align={paragraph.align}
            tone={paragraph.tone}
            size={paragraph.size}
            content={paragraph.Paragraph_text?.raw}
          />
        )}
        {cButtonLink && (
          <Box display="inlineBlock">
            <ButtonLink {...cButtonLink} />
          </Box>
        )}
        {cTextLink && (
          <Box display="inlineBlock">
            <TextLink {...cTextLink} />
          </Box>
        )}
      </Stack>
    </Box>
  </Box>
);
