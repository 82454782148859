import {
  Inline,
  ButtonIcon,
  IconSocialFacebook,
  IconSocialLinkedIn,
  IconMail,
  IconPrint,
  IconSocialX,
} from 'braid-design-system';
import { useCallback } from 'react';

type Props = {
  pageUrl: string;
  summary: string;
  title: string;
};

const sharedIconProps: Partial<React.ComponentProps<typeof ButtonIcon>> = {
  size: 'large',
  variant: 'soft',
};

const SocialMediaShare = ({ pageUrl, title, summary }: Props) => {
  const facebookUri = encodeURI(
    `https://www.facebook.com/sharer.php?u=${pageUrl}&p[title]=${title}`,
  );
  const twitterUri = encodeURI(
    `https://twitter.com/share?text=${title}&url=${pageUrl}`,
  );
  const linkedinUri = encodeURI(
    `https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}&title=${title}`,
  );
  const mailUri = encodeURI(
    `mailto:?subject=Sharing '${title}'&body="${summary}..." ${pageUrl}`,
  );

  const onSocialTagClick = useCallback(
    (url: string) => () => {
      window.open(url, '_blank');
    },
    [],
  );

  return (
    <Inline space="small" alignY="center">
      <ButtonIcon
        {...sharedIconProps}
        id="social-facebook-tag"
        icon={<IconSocialFacebook />}
        label="Share to Facebook"
        onClick={onSocialTagClick(facebookUri)}
      />
      <ButtonIcon
        {...sharedIconProps}
        id="social-twitter-tag"
        icon={<IconSocialX />}
        label="Share to twitter"
        onClick={onSocialTagClick(twitterUri)}
      />
      <ButtonIcon
        {...sharedIconProps}
        id="social-linkedin-tag"
        icon={<IconSocialLinkedIn />}
        label="Share to Linkedin"
        onClick={onSocialTagClick(linkedinUri)}
      />
      <ButtonIcon
        {...sharedIconProps}
        id="print-tag"
        icon={<IconPrint />}
        label="Print"
        onClick={() => window.print()}
      />
      <ButtonIcon
        {...sharedIconProps}
        id="mail-tag"
        icon={<IconMail />}
        label="Send Email"
        onClick={onSocialTagClick(mailUri)}
      />
    </Inline>
  );
};

export { SocialMediaShare };
