import {
  Box,
  Button,
  IconSearch,
  Autosuggest,
  Column,
  Columns,
} from 'braid-design-system';
import { useEffect, useState } from 'react';
import { isAboutSectionSearch } from '../../utils/searchHelper';

interface SearchBoxProps {
  minChars?: number;
  minCharsErrorMessage?: string;
  placeholderText?: string;
  searchButtonText?: string;
  page?: number;
  limit?: number;
  searchTerm?: string;
  onSearch?: (term: string) => void;
  onKeyPress?: (term: string) => void;
  onSelect?: (value: string) => void;
  titleSuggestions?: { text: string; value: string }[];
  section?: string;
  locale?: string;
}
export const SearchBox = ({
  minChars = 3,
  minCharsErrorMessage = '',
  placeholderText = '',
  searchButtonText = 'Search',
  searchTerm = '',
  onSearch,
  onKeyPress,
  onSelect,
  titleSuggestions,
  section = '',
  locale = '',
}: SearchBoxProps) => {
  const [inputText, setInputText] = useState(searchTerm);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [suggestions, setSuggestions] = useState(titleSuggestions ?? []);

  const isAboutSection = isAboutSectionSearch(section, locale);

  const isValid = (term: string) => term.length >= minChars;
  const validate = (term: string) => {
    if (!isValid(term)) {
      setErrorMessage(minCharsErrorMessage);
      return false;
    }
    setErrorMessage('');
    return true;
  };
  const handleOnClear = () => {
    setInputText('');
    setErrorMessage('');
  };
  const handleSearch = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validate(inputText)) {
      return;
    }

    if (onSearch) {
      onSearch(inputText);
    }
  };

  useEffect(() => {
    if (titleSuggestions) {
      setSuggestions(
        titleSuggestions.map((item) => {
          const suggestionText = item.text;
          const highlights: { start: number; end: number }[] = [];
          const isHighlightExists = (start: number) =>
            highlights.find((highlight) => highlight.start === start) !==
            undefined;

          if (inputText) {
            // Repeat on each term from inputText
            inputText
              .trim()
              .toLowerCase()
              .split(' ')
              .filter((x) => x)
              .map((eachTerm) => {
                let cursor = 0;

                while (cursor < suggestionText.length) {
                  const start = suggestionText
                    .toLowerCase()
                    .indexOf(eachTerm, cursor);
                  if (start === -1 || isHighlightExists(start)) {
                    break;
                  }
                  highlights.push({
                    start,
                    end: start + eachTerm.length,
                  });
                  cursor = start + eachTerm.length;
                }
              });
          }
          return {
            ...item,
            highlights: highlights.sort((a, b) => a.start - b.start),
          };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleSuggestions]);

  const handleOnChangeAutosuggest = (suggestion: {
    text: string;
    value?: string;
  }) => {
    setInputText(suggestion.text);
    setErrorMessage('');
    if (!isValid(suggestion.text)) {
      // do not auto suggest when < 3 chars
      setSuggestions([]);
      if (onKeyPress) {
        onKeyPress('');
      }
      return;
    }

    if (suggestion.value && onSelect) {
      onSelect(suggestion.value);
    } else if (onKeyPress) {
      onKeyPress(suggestion.text);
    }
  };

  return (
    <form onSubmit={handleSearch}>
      <Box>
        <Columns space="small" collapseBelow="tablet">
          <Column>
            <Autosuggest
              type="text"
              id="autosuggestInput"
              label=""
              placeholder={placeholderText}
              icon={<IconSearch />}
              onChange={(e) => handleOnChangeAutosuggest(e)}
              tone={errorMessage ? 'critical' : 'neutral'}
              message={errorMessage}
              value={{ text: inputText }}
              onClear={handleOnClear}
              suggestions={suggestions}
            />
          </Column>
          <Column width="content">
            {isAboutSection ? (
              <Button type={'submit'} variant={'solid'} tone={'formAccent'}>
                {searchButtonText}
              </Button>
            ) : (
              <Button type={'submit'}>{searchButtonText}</Button>
            )}
          </Column>
        </Columns>
      </Box>
    </form>
  );
};
