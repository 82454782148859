type Tone = 'promote' | 'info' | 'positive' | 'caution' | 'critical';

export const toBraidTone = (tone: string, fallback: Tone = 'promote'): Tone => {
  const values = [
    'promote',
    'info',
    'positive',
    'caution',
    'critical',
  ] as const;
  return values.find((value) => value === tone) || fallback;
};
