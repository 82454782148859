import React, { useState, type ReactNode } from 'react';
import { useTranslations } from '@vocab/react';
import {
  badWords,
  disallowHtml,
  maxLength,
  alphanumericSpace,
  isEmail,
  required,
  isPhone,
} from '@seek/validators-js';
import {
  Stack,
  TextField,
  Dropdown,
  RadioGroup,
  RadioItem,
  Inline,
  Actions,
  Button,
  Tiles,
  Box,
  Alert,
  Text,
  Heading,
  ButtonLink,
  TextLink,
  useResponsiveValue,
  FieldMessage,
} from 'braid-design-system';

import type { FormValues } from './WebForm';
import { FormType } from '@seek/cmsu-cms-connect';
import type { RenderParams } from '@seek/forms-ui';
import ReCAPTCHA from 'react-google-recaptcha';
import translations from './.vocab';
import { getIndustryOptions } from './helper';
import type { UseField } from '@seek/forms-ui/lib/Field/useField';
import type { RichTextContent } from '@graphcms/rich-text-types';
import { RichText } from '@seek/cmsu-rich-text';
import type { Language as FormLanguage } from '@seek/forms-ui/lib/types';
import type { Country, Locale } from '@seek/melways-sites';
import { useCMSUContext } from '../../hooks/useCMSUContext';
import { useTrackLink } from '@seek/cmsu-analytics';

type FormProps = RenderParams<FormValues>;

type TileColumns = 1 | 2 | 3 | 4 | 5 | 6 | undefined;

interface ComponentProps extends FormProps {
  country: Country;
  locale: Locale;
  sourceName: string;
  privacyUrl: string;
  recaptchaKey: string;
  useField: UseField<FormValues, FormLanguage>;
  setFieldValue: RenderParams<FormValues>['setFieldValue'];
  resetForm: () => void;
  columns: number;
  formType: FormType;
  confirmationMessage: RichTextContent;
  document?: string;
  wptSalesForceApi: string;
  privacyLink: string;
}

export const Form = ({
  handleSubmit,
  setFieldValue,
  useField,
  resetForm,
  country,
  locale,
  sourceName,
  privacyUrl,
  recaptchaKey,
  columns,
  formType,
  confirmationMessage,
  document,
  wptSalesForceApi,
  privacyLink,
}: ComponentProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState<string>('');
  const [recaptchaValue, setRecaptchaValue] = useState('');

  const { language } = useCMSUContext();

  const responsiveValue = useResponsiveValue();
  const { t } = useTranslations(translations);
  let actionText = t('Submit');
  switch (formType) {
    case FormType.DownloadLeadGenForm:
      actionText = t('Submit Download');
      break;
    default:
      break;
  }

  const onSubmitLink = useTrackLink('form-submission', {
    elementAction: actionText,
    elementText: actionText,
    elementDesign: 'button',
  });

  const getSiteName = () => {
    switch (locale) {
      case 'en-MY':
      case 'en-SG':
      case 'en-ID':
      case 'en-PH':
      case 'id-ID':
        return 'Jobstreet';
      case 'en-HK':
      case 'en-TH':
      case 'th-TH':
        return 'Jobsdb';
      default:
        return 'SEEK';
    }
  };

  const confirmationBox = () => {
    const isMobile = responsiveValue({
      mobile: true,
      desktop: false,
      tablet: false,
      wide: false,
    });
    const alignment = columns === 1 ? 'left' : 'center';
    if (formType === FormType.DownloadLeadGenForm) {
      const action = isMobile ? (
        <ButtonLink href={document || '#'} target="_blank" download>
          {t('Download Report')}
        </ButtonLink>
      ) : (
        <Inline space="small">
          <ButtonLink href={document || '#'} target="_blank" download>
            {t('Download Report')}
          </ButtonLink>
        </Inline>
      );
      return (
        <Box textAlign={alignment}>
          <Stack space="small" align={alignment}>
            <Heading level="2">{t('Confirmation')}</Heading>
            {confirmationMessage && <RichText content={confirmationMessage} />}
            <Box marginTop={'small'} textAlign={alignment}>
              {document ? action : <></>}
            </Box>
          </Stack>
        </Box>
      );
    }
    return (
      <Box textAlign={alignment}>
        <Stack space="small">
          <Heading level="2">{t('Confirmation')}</Heading>
          {confirmationMessage && <RichText content={confirmationMessage} />}
        </Stack>
      </Box>
    );
  };

  const onSubmit = handleSubmit((formValues: FormValues) => {
    if (!recaptchaValue) {
      setRecaptchaError(t('recaptcha verify'));
      return;
    }
    const data = {
      ...formValues,
      siteCountry: country.toUpperCase(),
      sourcePage: sourceName,
      recaptcha: recaptchaValue,
    };

    fetch(`${wptSalesForceApi}/hirer`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          setIsSubmitted(true);
          if (onSubmitLink) {
            onSubmitLink();
          }
        } else {
          resetForm();
          setSubscriptionError(true);
        }
      })
      .catch(() => {
        setSubscriptionError(true);
        resetForm();
      });
  });

  const industryList = getIndustryOptions(locale, t).filter((item) =>
    item.trim(),
  );

  const firstName = useField({
    id: 'firstName',
    validators: [required, badWords, disallowHtml, maxLength] as any,
    validatorProps: {
      max: 40,
    },
  });
  const lastName = useField({
    id: 'lastName',
    validators: [required, badWords, disallowHtml, maxLength] as any,
    validatorProps: {
      max: 40,
    },
  });
  const email = useField({
    id: 'email',
    validators: [required, disallowHtml, isEmail, maxLength] as any,
    validatorProps: {
      max: 255,
    },
  });
  const companyPhoneNumber = useField({
    id: 'companyPhoneNumber',
    validators: [required, disallowHtml, isPhone],
  });
  const companyName = useField({
    id: 'companyName',
    validators: [required, disallowHtml, maxLength] as any,
    validatorProps: {
      max: 255,
    },
  });
  const jobTitle = useField({
    id: 'jobTitle',
    validators: [required, disallowHtml, alphanumericSpace],
  });
  const industry = useField({
    id: 'industry',
  });
  const employerJobSeeker = useField({
    id: 'employerJobSeeker',
    validators: [required],
  });
  const hiringNeeds = useField({
    id: 'hiringNeeds',
    validators: [required],
  });
  const marketingComms = useField({
    id: 'marketingComms',
    validators: [required],
  });
  const doesBusinessWithSEEK = useField({
    id: 'doesBusinessWithSEEK',
    validators: [required],
  });

  return (
    <Box textAlign="left">
      {isSubmitted ? (
        confirmationBox()
      ) : (
        <form onSubmit={onSubmit} data-testid="WebFormTestId">
          <Stack space="medium">
            <Stack space="medium">
              <Tiles
                columns={{
                  mobile: 1,
                  desktop: columns as TileColumns,
                }}
                space="medium"
              >
                <TextField
                  id="firstName"
                  label={`${t('First Name')}*`}
                  message={!firstName.valid && firstName.errorMessage}
                  onClear={() => setFieldValue('firstName', '')}
                  tone={!firstName.valid ? 'critical' : 'neutral'}
                  {...firstName}
                />
                <TextField
                  id="lastName"
                  label={`${t('Last Name')}*`}
                  message={!lastName.valid && lastName.errorMessage}
                  onClear={() => setFieldValue('lastName', '')}
                  tone={!lastName.valid ? 'critical' : 'neutral'}
                  {...lastName}
                />
                <TextField
                  id="email"
                  label={`${t('Company Email')}*`}
                  message={!email.valid && email.errorMessage}
                  onClear={() => setFieldValue('email', '')}
                  tone={!email.valid ? 'critical' : 'neutral'}
                  {...email}
                />
                <TextField
                  id="companyPhoneNumber"
                  label={`${t('Phone Number')}*`}
                  message={
                    !companyPhoneNumber.valid && companyPhoneNumber.errorMessage
                  }
                  onClear={() => setFieldValue('companyPhoneNumber', '')}
                  tone={!companyPhoneNumber.valid ? 'critical' : 'neutral'}
                  {...companyPhoneNumber}
                />
                <TextField
                  id="companyName"
                  label={`${t('Company Name')}*`}
                  message={!companyName.valid && companyName.errorMessage}
                  onClear={() => setFieldValue('companyName', '')}
                  tone={!companyName.valid ? 'critical' : 'neutral'}
                  {...companyName}
                />
                <TextField
                  id="jobTitle"
                  label={`${t('Job Title')}*`}
                  message={!jobTitle.valid && jobTitle.errorMessage}
                  onClear={() => setFieldValue('jobTitle', '')}
                  tone={!jobTitle.valid ? 'critical' : 'neutral'}
                  {...jobTitle}
                />
                <Dropdown
                  label={t('Industry')}
                  id="industry"
                  placeholder={t('Industry placeholder')}
                  value={industry.value}
                  onChange={industry.onChange}
                  tone={industry.errorMessage ? 'critical' : 'neutral'}
                  message={industry.errorMessage}
                >
                  {industryList.map((item) => (
                    <option key={item}>{item}</option>
                  ))}
                </Dropdown>
              </Tiles>
            </Stack>
            <Stack space="medium">
              <Tiles
                columns={{
                  mobile: 1,
                  desktop: columns as TileColumns,
                }}
                space="small"
              >
                <RadioGroup
                  label={`${t('User Identity')}*`}
                  id="employerJobSeeker"
                  {...employerJobSeeker}
                  onChange={employerJobSeeker.onChange}
                  message={
                    !employerJobSeeker.valid && employerJobSeeker.errorMessage
                  }
                  tone={!employerJobSeeker.valid ? 'critical' : 'neutral'}
                >
                  <RadioItem label={t('Employer')} value="Employer" />
                  <RadioItem label={t('Job Seeker')} value="Job Seeker" />
                </RadioGroup>
                <RadioGroup
                  label={`${t('Hiring need')}*`}
                  id="hiringNeeds"
                  {...hiringNeeds}
                  onChange={hiringNeeds.onChange}
                  message={!hiringNeeds.valid && hiringNeeds.errorMessage}
                  tone={!hiringNeeds.valid ? 'critical' : 'neutral'}
                >
                  <RadioItem label={t('Yes')} value="Yes" />
                  <RadioItem label={t('No')} value="No" />
                </RadioGroup>
                <RadioGroup
                  label={`${t('Marketing required')}*`}
                  id="marketingComms"
                  {...marketingComms}
                  onChange={marketingComms.onChange}
                  message={!marketingComms.valid && marketingComms.errorMessage}
                  tone={!marketingComms.valid ? 'critical' : 'neutral'}
                >
                  <RadioItem label={t('Yes')} value="Yes" />
                  <RadioItem label={t('No')} value="No" />
                </RadioGroup>
                <RadioGroup
                  label={`${t('Organization advertise', {
                    site: getSiteName(),
                  })}*`}
                  id="doesBusinessWithSEEK"
                  {...doesBusinessWithSEEK}
                  onChange={doesBusinessWithSEEK.onChange}
                  message={
                    !doesBusinessWithSEEK.valid &&
                    doesBusinessWithSEEK.errorMessage
                  }
                  tone={!doesBusinessWithSEEK.valid ? 'critical' : 'neutral'}
                >
                  <RadioItem label={t('Yes')} value="Yes" />
                  <RadioItem label={t('No')} value="No" />
                  <RadioItem label={t('Not sure')} value="I am not sure" />
                </RadioGroup>
              </Tiles>
            </Stack>
            <ReCAPTCHA
              sitekey={recaptchaKey}
              onChange={(val: any) => {
                setRecaptchaValue(val);
                setRecaptchaError('');
              }}
              hl={language}
            />
            {recaptchaError ? (
              <FieldMessage
                id="recaptcha"
                tone="critical"
                message={recaptchaError}
              />
            ) : null}
            <Text size="xsmall" align="left">
              {t('Privacy Statement', {
                Link: (children: ReactNode) => (
                  <TextLink href={privacyUrl}>{children}</TextLink>
                ),
                privacyAgreement: privacyLink,
              })}
            </Text>
            <Actions>
              <Button type="submit" id="submitBtn">
                {actionText}
              </Button>
            </Actions>
            {subscriptionError && (
              <Box paddingTop="medium" paddingBottom="medium">
                <Alert tone="critical">
                  <Text>{t('Something Wrong')}</Text>
                </Alert>
              </Box>
            )}
          </Stack>
        </form>
      )}
    </Box>
  );
};
