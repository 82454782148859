import type {
  Article,
  Category,
  Locale,
  Section,
} from '@seek/cmsu-cms-connect';
import {
  Box,
  ContentBlock,
  Heading,
  Pagination,
  Stack,
  Tiles,
} from 'braid-design-system';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useLoaderData } from 'react-router-dom';

import { PageLayout } from '../components/PageLayout/PageLayout';
import { useAuth, useConfig } from '../shared/hooks';
import { CMS_PAGE_DISPLAYED_EVENT } from '../shared/utils/tealiumTracker/constants';
import {
  getLoggedInStatus,
  trackEvent,
} from '../shared/utils/tealiumTracker/tealiumAdapterHelper';

import {
  SubscriptionForm,
  Breadcrumbs,
  LatestArticlesByCategory,
} from '@seek/cmsu-components';
import { WPT_SALESFORCE_API } from '../../config';
import { convertMelwaysLocale } from '../../helpers/convertMelwaysLocale';
import type { LocalesInfo } from '../shared/utils/getLocales';
import { CMSUProvider } from '@seek/cmsu-components/src/hooks/useCMSUContext';
import { useMelwaysLink } from '@seek/melways-react';
import {
  enabledSubscriptionForm,
  getBreadcrumbItems,
} from '../shared/utils/helper';
import { getPageNumberFrom, getTotalPages } from '../shared/utils/Pagination';
import type { CategoryLocalization } from '../../helpers/loadersHelper';
import type { Language } from '@seek/melways-sites';
import { SearchBoxWrapper } from '../components/SearchBoxWrapper/SearchBoxWrapper';
import { ArticleItem } from '@seek/cmsu-components/src/modules/ArticleItem/ArticleItem';
import { ITEM_DISPLAY_TYPE } from '@seek/cmsu-components/src/helpers/articleHelper';

export const ArticlesCategory = () => {
  const { slug, pageNumber } = useParams();
  const { authenticationStatus } = useAuth();
  const { melwaysLocale, sourceName, privacyUrl, country, language } =
    useConfig();
  const locale = convertMelwaysLocale(melwaysLocale);

  const data = useLoaderData() as CategoryLocalization & LocalesInfo;
  const articles = data.articles.edges.map(
    ({ node: article }) => article as Article,
  );
  const category = data.category as Category;
  const totalArticles = data.articles.aggregate.count;

  // Casting because we shouldn't be able to see the category page without a
  // section anyway
  const section = category.relatedCategorySection as Section;

  const hasChildCategories = category.children.length > 0;
  const hasArticles = articles.length > 0;

  useEffect(() => {
    const { isPending, isLoggedIn } = getLoggedInStatus(authenticationStatus);
    if (!isPending) {
      trackEvent(CMS_PAGE_DISPLAYED_EVENT, {
        currentPage: category.name.toLocaleLowerCase() || '',
        isLoggedIn,
      });
    }
  }, [category, slug, authenticationStatus]);

  const parentCategoryName = category.parent?.name || '';
  const parentCategorySlug = category.parent?.slug || '';
  const parentSection =
    category.relatedCategorySection?.parentSection?.sectionName;
  const items = getBreadcrumbItems(
    locale as Locale,
    section.sectionName,
    parentCategoryName,
    parentCategorySlug,
    category.name || '',
    category.slug || '',
  );
  const showSubscriptionForm = enabledSubscriptionForm(section.sectionName);

  return (
    <PageLayout
      layoutProps={{
        title: category.name || '',
        ...(category.description?.text && {
          seo: { description: category.description.text },
        }),
        categoryName: slug,
        locales: data.locales,
        section: {
          sectionName: section.sectionName,
          theme: section?.theme === null ? undefined : section?.theme,
        },
      }}
    >
      <CMSUProvider config={{ language }}>
        {category.name && (
          <Box padding="large" background="surface">
            <ContentBlock width="medium">
              <Breadcrumbs items={items} />
            </ContentBlock>
          </Box>
        )}

        <Box paddingBottom="xlarge" background="surface">
          <ContentBlock width="medium">
            {category && (
              <Box
                paddingBottom="large"
                paddingX={{
                  mobile: 'large',
                  tablet: 'xlarge',
                  desktop: 'none',
                }}
              >
                <Heading level="1">{category.name}</Heading>
              </Box>
            )}

            <Box
              paddingX={{
                mobile: 'large',
                tablet: 'xlarge',
                desktop: 'none',
              }}
            >
              <Stack space="large">
                {hasChildCategories && <SearchBoxWrapper />}

                {hasArticles && (
                  <ChildCategoryLayout
                    articles={articles}
                    totalArticles={totalArticles}
                    language={language}
                    sectionSlug={section.sectionName}
                    categorySlug={slug ? slug : category.slug}
                    pageNumber={pageNumber}
                    parentSection={parentSection}
                    relatedSectionName={section.sectionName}
                  />
                )}

                {hasChildCategories && (
                  <Box paddingTop={hasArticles ? 'xlarge' : 'none'}>
                    <ParentCategoryLayout
                      category={category}
                      language={language}
                      section={section.sectionName}
                    />
                  </Box>
                )}
              </Stack>
            </Box>

            {showSubscriptionForm && (
              <Box
                marginTop={{
                  mobile: 'gutter',
                  tablet: 'xlarge',
                  desktop: 'xxlarge',
                }}
              >
                <SubscriptionForm
                  type={section.sectionName}
                  locale={melwaysLocale}
                  sourceName={sourceName}
                  privacyUrl={privacyUrl}
                  wptSalesForceApi={WPT_SALESFORCE_API}
                  country={country}
                  submitBtnStyle={{ tone: 'brandAccent' }}
                />
              </Box>
            )}
          </ContentBlock>
        </Box>
      </CMSUProvider>
    </PageLayout>
  );
};

type ChildCategoryLayoutProps = {
  articles: Article[];
  totalArticles: number;
  language: Language;
  sectionSlug: string;
  categorySlug: string;
  pageNumber: string | undefined;
  parentSection: string | undefined;
  relatedSectionName: string | undefined;
};

const ChildCategoryLayout = ({
  articles,
  totalArticles,
  language,
  sectionSlug,
  categorySlug,
  pageNumber,
  parentSection,
  relatedSectionName,
}: ChildCategoryLayoutProps) => {
  const urlResolver = useMelwaysLink();

  const totalPages = getTotalPages(totalArticles);
  const currentPageNumber = getPageNumberFrom(pageNumber);
  const hasMoreThanOnePage = totalPages > 1;
  const section = parentSection
    ? `${sectionSlug}/${relatedSectionName}`
    : sectionSlug;
  return (
    <Stack space="large">
      <Tiles
        space="gutter"
        columns={{
          mobile: 1,
          tablet: 2,
          desktop: 3,
        }}
      >
        {articles &&
          articles.map((article) => (
            <ArticleItem
              displayType={ITEM_DISPLAY_TYPE.CARD}
              article={article as Article}
              language={language}
              section={section}
              hideCategory={true}
              key={article.id}
            />
          ))}
      </Tiles>
      {hasMoreThanOnePage && (
        <Box>
          <Pagination
            page={currentPageNumber}
            total={totalPages}
            label="pagination"
            linkProps={({ page: pageInPagination }) => ({
              href: `#`,
              onClick: (e) => {
                e.preventDefault();

                window.location.href = urlResolver({
                  language,
                  path: `/${section}/category/${categorySlug}/${pageInPagination}`,
                });
              },
            })}
          />
        </Box>
      )}
    </Stack>
  );
};

type ParentCategoryLayoutProps = {
  category: Category;
  language: Language;
  section?: string;
};

const ParentCategoryLayout = ({
  category,
  language,
  section,
}: ParentCategoryLayoutProps) => {
  const childCategoryWithArticles = category.children.filter(
    (child) => child.relatedArticlesInCategory.length > 0,
  );
  return (
    <Stack space="xlarge">
      {childCategoryWithArticles.map((child) => (
        <LatestArticlesByCategory
          key={child.id}
          category={child}
          language={language}
          sectionOverride={section}
        />
      ))}
    </Stack>
  );
};
