import type { CardJobAdFragment } from '@seek/cmsu-cms-connect';
import { Box, Stack, Inline, IconPromote, Text } from 'braid-design-system';
import { Heading } from '../Heading/Heading';
import { ActionGroup } from '../ActionGroup/ActionGroup';
import { Paragraph } from './Paragraph';

import {
  cardStyle,
  featureCardStyle,
  featureBar,
  imageStyle,
} from './styles.css';

type Props = Pick<CardJobAdFragment, 'badge' | 'paragraph' | 'actionGroup'> & {
  language?: string;
  heading?: React.ComponentProps<typeof Heading> | null;
  subHeading?: string | null;
  image?: {
    url: string;
    alt?: string | null;
  } | null;
  rows: number;
};

const LINE_HEIGHT = 28;

const JobAd = ({
  heading,
  subHeading,
  paragraph,
  actionGroup,
  image,
  badge,
  rows,
}: Props) => {
  const minHeight = `${rows * LINE_HEIGHT}px`;
  return (
    <Box
      borderRadius="large"
      background="surface"
      padding="medium"
      paddingY="large"
      height="full"
      boxShadow="borderNeutralLight"
      width="full"
      className={`${cardStyle} ${badge?.text && featureCardStyle}`}
      position="relative"
      data-testid="JobAdTestId"
    >
      {badge?.text && (
        <Box background={badge?.tone || 'promote'} className={featureBar}>
          <Inline space="xsmall" alignY="center" align="center">
            <IconPromote size="small" />
            <Text>{badge.text}</Text>
          </Inline>
        </Box>
      )}

      <Box style={{ height: '100%' }}>
        <Stack space={'large'}>
          {image ? (
            <Box
              width="full"
              className={imageStyle}
              style={{
                backgroundImage: `url(${image.url})`,
              }}
            />
          ) : null}
          {heading && (
            <Box marginBottom="small" textAlign={'center'}>
              <Heading {...heading} />
            </Box>
          )}
          {subHeading ? (
            <Box paddingX="gutter" style={{ minHeight: `${minHeight}` }}>
              <Text size="large" tone="secondary" align="center">
                {subHeading}
              </Text>
            </Box>
          ) : null}

          {actionGroup && (
            <Box textAlign={'center'}>
              <ActionGroup {...actionGroup} size="full" />
            </Box>
          )}
          {paragraph && (
            <Paragraph content={paragraph.Paragraph_text?.raw} {...paragraph} />
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export { JobAd };
