import type { Article } from '@seek/cmsu-cms-connect';
import {
  Badge,
  Box,
  Divider,
  Heading,
  Link,
  Stack,
  Text,
} from 'braid-design-system';
import React from 'react';
import { interactive } from './styles.css';

type Props = {
  article: Article;
  articleLink: string;
  articleItemFooterText: string;
  hideCategory?: boolean;
  isLastItem?: boolean;
};

export const ArticleListItem = ({
  article,
  articleLink,
  articleItemFooterText,
  hideCategory = false,
  isLastItem = false,
}: Props) => (
  <Stack space="gutter">
    <Box
      borderRadius="large"
      display="flex"
      style={{
        gap: '24px',
      }}
    >
      <Box data-testid="ArticleFeatureImageTestId">
        <img
          src={article.featureImage[0]?.url}
          style={{
            width: '200px',
            borderRadius: '6px',
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="spaceBetween"
        flexDirection={'column'}
        padding={{ mobile: 'gutter', tablet: 'none' }}
      >
        <Box paddingBottom="large">
          <Stack space="gutter">
            {!hideCategory && article.category && (
              <Box data-testid="ArticleCategoryTestId">
                <Badge tone="neutral">{article.category.name}</Badge>
              </Box>
            )}

            <Link href={articleLink} className={interactive}>
              <Heading level="4">{article.title}</Heading>
            </Link>
          </Stack>
        </Box>
        <Text tone="secondary" size="small">
          {articleItemFooterText}
        </Text>
      </Box>
    </Box>

    {!isLastItem && <Divider weight="regular" />}
  </Stack>
);
