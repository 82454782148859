import type { Category } from '@seek/cmsu-cms-connect';
import { useMelwaysLink } from '@seek/melways-react';
import type { Language } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import translations from './.vocab';

import { Box, ButtonLink, Heading, Stack, Tiles } from 'braid-design-system';

import { ArticleItem } from '../ArticleItem/ArticleItem';
import { ITEM_DISPLAY_TYPE } from '../../helpers/articleHelper';
import { useScreenSize, type ViewPoints } from '../../hooks/useScreenSize';

type Props = {
  category: Category;
  language: Language;
  sectionOverride?: string;
};
export const LatestArticlesByCategory = ({
  category,
  language,
  sectionOverride,
}: Props) => {
  const urlResolver = useMelwaysLink();
  const { t } = useTranslations(translations);
  const screenSize = useScreenSize();

  if (
    category.relatedArticlesInCategory &&
    category.relatedArticlesInCategory.length &&
    category.relatedArticlesInCategory.length === 0
  )
    return;

  const articlesToDisplay = getArticlesToDisplayByScreenSize(screenSize);
  const showBrowseAll =
    category.relatedArticlesInCategory.length > articlesToDisplay;

  const section = sectionOverride
    ? sectionOverride
    : getSectionFromCategory(category);

  const categoryLink = urlResolver({
    language,
    path: `/${section}/category/${category.slug}`,
  });

  return (
    <Stack space="gutter">
      <Heading level="2">
        <span data-testid="Category_Name_TestId">{category.name}</span>
      </Heading>
      <Tiles
        space="gutter"
        columns={{
          mobile: 1, // on mobile, we still show 3 articles but in a single column
          tablet: articlesToDisplay,
          wide: articlesToDisplay,
        }}
      >
        {category.relatedArticlesInCategory
          .slice(0, articlesToDisplay)
          .map((article) => (
            <span
              key={article.id}
              data-testid={`ArticleItem_${article.id}_TestId`}
            >
              <ArticleItem
                displayType={ITEM_DISPLAY_TYPE.CARD}
                article={article}
                language={language}
                section={section}
              />
            </span>
          ))}
      </Tiles>
      {showBrowseAll && (
        <Box
          style={{ minWidth: '115px' }}
          display="inlineBlock"
          data-testid="BrowseAllButtonTestId"
        >
          <ButtonLink href={categoryLink} variant="ghost" tone="neutral">
            {t('Browse all')}
          </ButtonLink>
        </Box>
      )}
    </Stack>
  );
};

export const getArticlesToDisplayByScreenSize = (
  screenSize: ViewPoints,
): 1 | 2 | 3 => {
  if (screenSize.isTablet) return 2;
  return 3;
};

const getSectionFromCategory = (category: Category) =>
  category.relatedCategorySection?.parentSection?.sectionName
    ? `${category.relatedCategorySection?.parentSection?.sectionName}/${category.relatedCategorySection?.sectionName}`
    : category.relatedCategorySection?.sectionName;
