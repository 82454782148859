export const HYGRAPH_CONTENT_API = process.env.HYGRAPH_CONTENT_API ?? '';
export const WPT_SALESFORCE_API = process.env.WPT_SALESFORCE_API ?? '';
export const SEARCH_API_ENDPOINT = process.env.SEARCH_API_ENDPOINT ?? '';
export const CMSU_HYGRAPH_PREVIEW_TOKEN =
  process.env.CMSU_HYGRAPH_PREVIEW_TOKEN ?? '';
export const CMSU_HYGRAPH_PREVIEW_TOKEN_VALUE =
  process.env.CMSU_HYGRAPH_PREVIEW_TOKEN_VALUE ?? '';
export const HYGRAPH_CONTENT_API_TOKEN =
  process.env.HYGRAPH_CONTENT_API_TOKEN ?? '';
export const ENABLE_DATADOG_RUM = process.env.ENABLE_DATADOG_RUM;
