import type { UserProfile } from '@seek/apac-candidate-header-footer/types';
import type { HeaderFooterContext } from 'src/gql/generated';

export const mockHirerAccount: HeaderFooterContext = {
  identityContext: {
    email: 'dev123@test.com',
  },
  user: {
    name: 'Test User',
    firstName: 'Test',
    id: '123456789',
  },
  advertiser: {
    name: 'Test Company',
    billingId: '987654321',
    isActivationPending: false,
    isAgency: false,
    permissionOptions: {
      canCreateJobs: true,
      canManageBrand: false,
      canManageCompanyProfile: false,
      canManageUsers: false,
      canPayInvoices: false,
      canViewInvoiceHistory: false,
      canViewManagerReports: false,
    },
    showOnCreditHoldMessage: false,
    hasMultipleAccounts: false,
  },
};

export const mockCaAccount: UserProfile & { id: number } = {
  firstName: 'Test',
  lastName: 'User',
  email: 'dev123@test.com',
  id: 123456789,
};
