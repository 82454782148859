import {
  Box,
  Dropdown,
  Heading,
  Stack,
  Text,
  Tiles,
} from 'braid-design-system';
import React, { useEffect, useState } from 'react';
import { ArticleItem } from '../ArticleItem/ArticleItem';
import type { Article, TopicListFragment, Topic } from '@seek/cmsu-cms-connect';
import type { Language } from '@seek/melways-sites';
import { ITEM_DISPLAY_TYPE } from '../../helpers/articleHelper';
import { richTextHelper } from '@seek/cmsu-rich-text/src/utils/richTextHelper';

type Props = {
  topicList: TopicListFragment;
  language: Language;
};

export const TopicList = ({ topicList, language }: Props) => {
  const defaultTopic = getDefaultTopic(topicList);
  const [selectedTopic, setSelectedTopic] = useState<string>(defaultTopic);
  const [currentTopic, setCurrentTopic] = useState<Topic | undefined>(
    undefined,
  );

  useEffect(() => {
    if (topicList)
      setCurrentTopic(findTopicBy(topicList.topics as Topic[], selectedTopic));
  }, [selectedTopic, topicList, topicList.topics]);

  if (!isTopicListValid(topicList)) return;

  return (
    <Stack space="large">
      <Tiles
        space="gutter"
        columns={{
          mobile: 1,
          desktop: 2,
        }}
      >
        <Box display="flex" alignItems="center" height="full">
          <Heading level="2">{topicList.topicFilterLabel}</Heading>
        </Box>
        <Dropdown
          id="ARTICLE_GROUP"
          label=""
          onChange={(event) => {
            setSelectedTopic(event.currentTarget.value);
          }}
          value={selectedTopic}
          placeholder={topicList.topicFilterPlaceholder || ''}
        >
          <>
            {topicList.topics.map((topic, index) => (
              <option key={index} value={topic.name}>
                {topic.name}
              </option>
            ))}
          </>
        </Dropdown>
      </Tiles>

      <Text>
        {currentTopic &&
          richTextHelper.getTextWithoutFormatting(currentTopic.description)}
      </Text>
      <Tiles
        space="gutter"
        columns={{
          mobile: 1,
          tablet: 2,
          desktop: 3,
        }}
      >
        {currentTopic &&
          currentTopic.relatedArticlesInTopic &&
          currentTopic.relatedArticlesInTopic.map((article) => (
            <ArticleItem
              displayType={ITEM_DISPLAY_TYPE.CARD}
              article={article as Article}
              language={language}
              hideCategory={true}
              key={article.id}
            />
          ))}
      </Tiles>
    </Stack>
  );
};

const isTopicListValid = (topicList: TopicListFragment): boolean =>
  (topicList && topicList.topics && topicList.topics.length > 0) || false;

const getDefaultTopic = (topicList: TopicListFragment): string =>
  isTopicListValid(topicList) && topicList.setDefaultTopic
    ? topicList.topics[0].name
    : '';

const findTopicBy = (topics: Topic[], topicName: string): Topic | undefined =>
  topics.find((topic) => topic.name === topicName);

export const topicListHelper = {
  isTopicListValid,
  getDefaultTopic,
  findTopicBy,
};
