import type { CTestimonial } from '@seek/cmsu-cms-connect';
import { Box, Stack, Strong, Text } from 'braid-design-system';
import { vars } from 'braid-design-system/css';

import * as styles from './TestimonialCard.css';

export const TestimonialCard = ({
  testimonial,
}: {
  testimonial: CTestimonial;
}) => (
  <Box
    borderRadius="large"
    boxShadow="borderNeutralLight"
    background="surface"
    padding="gutter"
    data={{ testid: 'testimonial-card' }}
    textAlign="left"
    style={{ height: 330 }}
  >
    {/* Note the semantic elements being used throughout */}
    <Box component="blockquote">
      <Stack space="large">
        <Stack space="medium">
          <Box
            component="span"
            display="block"
            className={styles.quoteMark}
            style={{
              color: vars.foregroundColor.brandAccent,
              fontSize: '64px',
            }}
          >
            &ldquo;
          </Box>

          <Box
            style={{
              height: 152,
              overflow: 'hidden',
            }}
          >
            <Text component="p">{testimonial.quote}</Text>
          </Box>
        </Stack>

        <Text component="footer" size="small">
          <Stack space="xxsmall">
            <Strong>{testimonial.author}</Strong>
            {testimonial.company ? <>{testimonial.company}</> : null}
          </Stack>
        </Text>
      </Stack>
    </Box>
  </Box>
);
