import type { GetPagesQuery } from '@seek/cmsu-cms-connect';
import React from 'react';
import { useLoaderData, useParams } from 'react-router';
import { useConfig } from 'src/client/shared/hooks';
import { PageComponentWrapper } from '../../components/PageComponent/PageComponentWrapper';
import { usePageTracking } from 'src/client/shared/hooks/usePageTracking';
import { deHyphen } from 'src/client/shared/utils/tealiumTracker/tealiumAdapterHelper';

export const Page = () => {
  const { fullURL, melwaysLocale, section } = useConfig();
  const { slug } = useParams();
  const page = useLoaderData() as GetPagesQuery['pages'][0];
  const previousSiteSection = deHyphen(page.slug);

  usePageTracking(page.title.toLocaleLowerCase(), slug, previousSiteSection);

  return (
    <PageComponentWrapper
      page={page}
      fullURL={fullURL}
      melwaysLocale={melwaysLocale}
      enabledSubscriptionForm={[
        'hiring-advice',
        'hiring-advice-test',
        'market-insights',
        'market-insights-test',
      ].includes(section)}
    />
  );
};
