import React from 'react';
import type { GetPagesQuery } from '@seek/cmsu-cms-connect';
import { PageComponent } from '@seek/cmsu-components';
import { useTargetBlank } from 'src/client/shared/hooks/useTargetBlank';
import { PageLayout } from '../PageLayout/PageLayout';
import type { LayoutProps } from '../../shared/types';
import { transformSeoData } from 'src/helpers/seo';
import { useConfig } from 'src/client/shared/hooks';
import { WPT_SALESFORCE_API } from '../../../config';
import { pageRenderers } from '../../../helpers/pageRenderers';
import { getLocales } from 'src/client/shared/utils/getLocales';
import { usePageTrackingData } from 'src/client/shared/hooks/usePageTracking';
import { tealiumAdapter } from 'src/client/shared/utils/tealiumTracker/tealiumAdapterHelper';
import { AnalyticsProvider } from '@seek/cmsu-analytics';
import {
  CONTENT_ELEMENT_DISPLAYED,
  CONTENT_ELEMENT_PRESSED_EVENT,
  PROMOTIONAL_BANNER_DISPLAYED,
} from 'src/client/shared/utils/tealiumTracker/constants';
import { getPreviewDocumentLink } from 'src/helpers/loadersHelper';

const layoutProps: LayoutProps = {
  title: 'Loading..',
};

export const PageComponentWrapper = ({
  page,
  melwaysLocale,
  fullURL,
  enabledSubscriptionForm,
}: {
  page: GetPagesQuery['pages'][0];
  melwaysLocale: string;
  fullURL: string;
  enabledSubscriptionForm?: boolean;
}) => {
  const {
    title,
    slug,
    banner,
    relatedPageSection,
    seo,
    localizations,
    sites,
    promotionalBanner,
  } = page;
  const bannerImage =
    banner?.__typename === 'CBannerPrimary'
      ? banner?.BannerPrimary_image?.url
      : banner?.image.url;

  const promotionalBannerTrackingData = {
    [promotionalBanner?.id || '']: {
      eventName: PROMOTIONAL_BANNER_DISPLAYED,
    },
  };
  const pageSeo = transformSeoData(seo || {}, {
    title,
    image: bannerImage,
  });
  useTargetBlank();

  const config = useConfig();
  const {
    privacyUrl,
    sourceName,
    country,
    language,
    site,
    pathname,
    utmParameters,
  } = useConfig();

  const locales = getLocales(
    site,
    pathname.replace(`/${language}/`, ''),
    sites,
    localizations,
  );

  const section = relatedPageSection || {};

  const currentPage = page.title.toLocaleLowerCase();
  const trackingData = usePageTrackingData(currentPage);
  page.containers.forEach((container) => {
    container.blocks?.forEach((block) => {
      block.items.forEach((item) => {
        if (item.__typename === 'CBanner') {
          if (item.banner_items?.__typename === 'CBannerArticle') {
            const article = item.banner_items.article;
            if (article) {
              const featureArticleData = {
                id: article?.id,
                isArticle: true,
                title: article?.title,
                slug: article?.slug,
                locale: page.locale,
                site,
                link: '',
                sectionName:
                  article?.category?.relatedCategorySection?.sectionName,
                parentSectionName:
                  article?.category?.relatedCategorySection?.parentSection
                    ?.sectionName,
                stage: undefined,
              };
              if (item.banner_items?.article) {
                item.banner_items.article.url =
                  getPreviewDocumentLink(config, featureArticleData) || '';
              }
            }
          }
        }
      });
    });
  });

  return (
    <AnalyticsProvider
      analytics={{
        data: trackingData,
        tealiumAdapter,
        events: {
          'button-link': {
            eventName: CONTENT_ELEMENT_PRESSED_EVENT,
          },
          'text-link': {
            eventName: CONTENT_ELEMENT_PRESSED_EVENT,
          },
          'image-link': {
            eventName: CONTENT_ELEMENT_PRESSED_EVENT,
          },
          'dropdown-link': {
            eventName: CONTENT_ELEMENT_PRESSED_EVENT,
          },
          'accordion-label': {
            eventName: CONTENT_ELEMENT_PRESSED_EVENT,
          },
          'disclosure-label': {
            eventName: CONTENT_ELEMENT_PRESSED_EVENT,
          },
          'form-submission': {
            eventName: CONTENT_ELEMENT_DISPLAYED,
          },
          ...(promotionalBanner?.id && promotionalBannerTrackingData),
        },
      }}
    >
      <PageLayout
        layoutProps={{
          ...layoutProps,
          title,
          section,
          seo: pageSeo,
          categoryName: slug,
          locales,
        }}
      >
        <PageComponent
          language={language}
          melwaysLocale={melwaysLocale}
          page={page}
          fullURL={fullURL}
          enabledSubscriptionForm={enabledSubscriptionForm}
          renderers={pageRenderers}
          subscriptionFormPrivacyUrl={privacyUrl}
          subscriptionFormSourceName={sourceName}
          country={country}
          wptSalesForceApi={WPT_SALESFORCE_API}
          utmParameters={utmParameters}
        />
      </PageLayout>
    </AnalyticsProvider>
  );
};
