import { useEffect, useState } from 'react';
import { SearchBox } from '@seek/cmsu-components';
import { Stage, type Theme, type Article } from '@seek/cmsu-cms-connect';
import { useNavigate, useParams } from 'react-router';
import {
  Box,
  ContentBlock,
  Heading,
  Pagination,
  Stack,
  Loader,
} from 'braid-design-system';
import {
  useAuth,
  useConfig,
  PAGE_LIMIT,
  useHandleSearch,
  useHandleSearchResultsOnMount,
} from '../shared/hooks';
import { useTranslations } from '@vocab/react';
import translations from '../.vocab';
import { PageLayout } from '../components/PageLayout/PageLayout';
import { useFetchSuggestions } from '../shared/hooks/useFetchSuggestions';
import {
  getLoggedInStatus,
  getPreviousSiteSection,
  trackEvent,
} from '../shared/utils/tealiumTracker/tealiumAdapterHelper';
import {
  CMS_PAGE_DISPLAYED_EVENT,
  SEARCH_RESULTS,
} from '../shared/utils/tealiumTracker/constants';
import { convertMelwaysLocale } from '../../helpers/convertMelwaysLocale';
import { useMelwaysLink } from '@seek/melways-react';
import { NoSearchResult } from './pageComponents/NoSearchResult';
import type { SearchResultsResponse } from '../shared/hooks/useSearchResultHooks';
import type { Language } from '@seek/melways-sites';
import { useScreenSize } from '../hooks/useScreenSize';
import { ArticleItem } from '@seek/cmsu-components/src/modules/ArticleItem/ArticleItem';
import { ITEM_DISPLAY_TYPE } from '@seek/cmsu-components/src/helpers/articleHelper';
import { resizeImageFromHygraph } from '@seek/cmsu-components/src/utils/imageHelper';
import {
  getSearchPathBy,
  isAboutSectionSearch,
} from '@seek/cmsu-components/src/utils/searchHelper';

type SearchData = {
  id: string;
  title: string;
  slug: string;
  categoryName: string;
  sectionName: string;
  parentSectionName: string;
  publishedAt: string;
  featureImage: {
    width: number;
    height: number;
    url: string;
    alt: string;
  };
};

const getParentSection = (searchResultsData: SearchData) => {
  if (!searchResultsData.parentSectionName) return;
  return {
    parentSection: {
      sectionName: searchResultsData.parentSectionName,
    },
  };
};

const mapSearchResultsToArticle = (searchResultsData: SearchData): Article =>
  // Map the search results to Article type
  ({
    id: searchResultsData.id,
    title: searchResultsData.title,
    slug: searchResultsData.slug,
    category: {
      name: searchResultsData.categoryName,
      relatedCategorySection: {
        sectionName: searchResultsData.sectionName,
        ...getParentSection(searchResultsData),
      },
    },
    publishDate: searchResultsData.publishedAt,
    featureImage: [
      {
        ...(searchResultsData.featureImage?.url && {
          url: resizeImageFromHygraph({
            imageUrl: searchResultsData.featureImage.url,
            width: 480,
            height: 320,
          }),
        }),
        ...(searchResultsData.featureImage?.alt && {
          alt: searchResultsData.featureImage.alt,
        }),
        ...(searchResultsData.featureImage?.width && {
          width: searchResultsData.featureImage.width,
        }),
        ...(searchResultsData.featureImage?.height && {
          height: searchResultsData.featureImage.height,
        }),
      },
    ],
    stage: Stage.Published,
  } as Article);

export const SearchResults = () => {
  const { term, pageNumber } = useParams();
  const { authenticationStatus } = useAuth();
  const decodedTerm = decodeURIComponent(term || '');
  const navigate = useNavigate();
  const { t } = useTranslations(translations);
  const { melwaysLocale, language, section } = useConfig();
  const locale = convertMelwaysLocale(melwaysLocale);
  const [searchInput, setSearchInput] = useState<string>('');
  const urlResolver = useMelwaysLink();
  const fetchedSuggestions = useFetchSuggestions(locale, searchInput);
  const {
    handleSearch,
    searchedTerm,
    isSearchInProgress,
    searchResults,
    setSearchedTerm,
  } = useHandleSearch();
  const searchResultsTotal = searchResults?.total;
  useHandleSearchResultsOnMount({
    handleSearch,
  });

  const isAboutSection = isAboutSectionSearch(section, locale);
  const selectedTheme = (isAboutSection ? 'seekJobs' : 'apac') as Theme;

  useEffect(() => {
    const { isPending, isLoggedIn } = getLoggedInStatus(authenticationStatus);

    if (!isPending && searchResultsTotal !== undefined) {
      trackEvent(CMS_PAGE_DISPLAYED_EVENT, {
        currentPage: SEARCH_RESULTS,
        siteSection: getPreviousSiteSection() || SEARCH_RESULTS,
        searchKeyword: decodedTerm,
        searchResultsTotal,
        pageNumber,
        isLoggedIn,
      });
    }
  }, [
    handleSearch,
    pageNumber,
    decodedTerm,
    searchResultsTotal,
    authenticationStatus,
  ]);

  return (
    <PageLayout
      layoutProps={{
        title: t('Search results'),
        section: { theme: selectedTheme },
      }}
    >
      <Box
        paddingY="xlarge"
        paddingX={{
          mobile: 'medium',
          desktop: 'none',
        }}
        background="surface"
      >
        <Box paddingY="small">
          <ContentBlock>
            <Stack space={'large'}>
              <Box
                flexDirection={'row'}
                display={'flex'}
                paddingBottom={'large'}
              >
                <Heading level="2">
                  {`${
                    searchResults && searchResults.total > 0
                      ? searchResults.total
                      : `${t('No')}`
                  } ${t('Search Results for')} ’${searchedTerm}’`}
                  &nbsp;
                </Heading>
              </Box>
            </Stack>

            <Box paddingBottom={'medium'}>
              <SearchBox
                searchTerm={searchedTerm}
                placeholderText={
                  isAboutSection
                    ? t('Search box news suggestion')
                    : t('Search box suggestion')
                }
                searchButtonText={t('Search button text')}
                section={section}
                locale={locale}
                onSearch={(s) => {
                  navigate(
                    urlResolver({
                      language,
                      path: `/${getSearchPathBy(section)}/${encodeURIComponent(
                        s,
                      )}/1`,
                    }),
                  );
                  if (s !== searchedTerm) {
                    setSearchedTerm(s);
                  } else {
                    handleSearch(s);
                  }
                }}
                minChars={3}
                minCharsErrorMessage={t('Minimum chars error message', {
                  minChars: '3',
                })}
                onKeyPress={setSearchInput}
                onSelect={(path) => {
                  // For some reason using useNavigate is not forcing SSR loader to work for Articles when being selected.
                  // We are using window.location.href to force SSR loader to work and route outside of react router.
                  // The apollo client is not making a full network request and returning the data from the cache and it is empty.
                  window.location.href = `${window.location.origin}${path}`;
                }}
                titleSuggestions={fetchedSuggestions}
              />
            </Box>
            {isSearchInProgress && (
              <Box paddingBottom={'medium'}>
                <Loader delayVisibility={true} size={'large'} />
              </Box>
            )}
            {searchResults && !isSearchInProgress && (
              <DisplayArticleItem
                searchResults={searchResults}
                language={language}
              />
            )}
            {!isSearchInProgress &&
              searchResults &&
              searchResults.total > 0 && (
                <Box padding={'xlarge'}>
                  <Pagination
                    page={Number(pageNumber ?? 1)}
                    total={Math.ceil(searchResults.total / PAGE_LIMIT)}
                    label={'pagination'}
                    linkProps={({ page: pageInPagination }) => ({
                      href: `#`,
                      onClick: (e) => {
                        e.preventDefault();
                        navigate(
                          urlResolver({
                            language,
                            path: `/article-search/${searchedTerm}/${pageInPagination}`,
                          }),
                        );
                      },
                    })}
                  />
                </Box>
              )}

            {!isSearchInProgress && !searchResults && (
              <NoSearchResult searchedTerm={searchedTerm} />
            )}
          </ContentBlock>
        </Box>
      </Box>
    </PageLayout>
  );
};

const DisplayArticleItem = ({
  searchResults,
  language,
}: {
  searchResults: SearchResultsResponse;
  language: Language;
}) => {
  const screenSize = useScreenSize();

  return (
    <Stack space="gutter">
      {searchResults.data.map((data, index) => (
        <ArticleItem
          displayType={
            screenSize.isMobile
              ? ITEM_DISPLAY_TYPE.CARD
              : ITEM_DISPLAY_TYPE.LIST_ITEM
          }
          article={mapSearchResultsToArticle(data as SearchData)}
          language={language}
          key={data.id}
          isLastItem={index === searchResults.data.length - 1}
        />
      ))}
    </Stack>
  );
};
