import { Box } from 'braid-design-system';
import { useEffect, useState } from 'react';

export const BugcrowdForm = () => {
  const [element, setElement] = useState<HTMLElement | null>(null);

  // Using the ref here to display on the client side when the element renders.
  // It's done this way so that it works on Storybook.
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src =
      'https://bugcrowd.com/9e54bdad-7bad-4d49-abe6-e75f0d0dba0d/external/script';
    script.setAttribute(
      'data-bugcrowd-program',
      'https://bugcrowd.com/9e54bdad-7bad-4d49-abe6-e75f0d0dba0d/external/report',
    );

    element?.appendChild(script);

    return () => {
      element?.removeChild(script);
    };
  }, [element]);

  return (
    <Box
      data-testid="bugcrowd-form-container"
      ref={setElement}
      component="div"
    />
  );
};
