import "../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@20.14.9_babel-plugin-macros@3.1.0_webpack@5.92.1/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA62Sy1LDMAxF9/0KbZihC3eSPonZ8CeMsdVUNLGD40AKw78zdh0woYUuWOXh66sj6c5kvck3bQ5vEwCHvWOiolJzkKgd2tsJwA6p3DkOeZZd+e9a9OyFlNt9/zXIllnW9LeT98nsaD3/P+vFyHrxi/WDkPvSmk4rZrFB4ThoE19H5y29IgdptBOk/dnf3QG8MtIKew5FkRAtA5FphCR34JDN0sNVOEwKS1MZy+FZ2GvG7vPFU7+vxLybBj5jFVpmhaKuHWvkNLHdBFtFbVOJA4dthQHwsWsdbQ/M94Xa/Tb2RihFumTONONKi2kqeDDOmfqU5pPm5jSNfzJFFqUj4xdlqq4Oww6bY+SwblPGk7NxaaXiOM7jnCrc+oaaHlpTkTpxLzKU1rxwyNM1f8uUCK5nq/s02JL0j60FsrsaFQlopUXUILSC65r0EOnNMmv6afCPxbLwAeBFw1LWQ8Lev3TLqIt7GBdfBbCLo3VBuLwoUq+PGUloVpfRjO//NZ6imI/GM7QdBauzJGcF6yj4zKM2GiPOB5D/Gif7BAAA\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@20.14.9_babel-plugin-macros@3.1.0_webpack@5.92.1/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var BannerImageColumnStyle = 'cm717s6';
export var BannerTextColumnBoxStyle = 'cm717s4';
export var ShopfrontBannerTextColumnBoxStyle = 'cm717s5';
export var VerticalDivider = 'cm717s8';
export var VerticalDividerText = 'cm717sa';
export var VerticalDividerWrapper = 'cm717s7';
export var VerticalLine = 'cm717s9';
export var bannerContainer = 'cm717s0';
export var headerImageStyle = 'cm717s2';
export var headerImageStyleEnlarged = 'cm717s1';
export var tertiaryImageStyle = 'cm717s3';