
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiLCJBcHBsZSBwb2RjYXN0cyI6IkFwcGxlIHBvZGNhc3RzIiwiR29vZ2xlIHBvZGNhc3RzIjoiR29vZ2xlIHBvZGNhc3RzIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlNwb3RpZnkgcG9kY2FzdHMifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJbxaDHmseax5rDn8Whw6fFmcOsw6zDrMOf4bq94bq94bq9IOG5r8O2w7bDtiDDtsO2w7bHmseax5rFmSDGpcO2w7bDtsaMw6fEg8SDxIPFoeG5r10iLCJBcHBsZSBwb2RjYXN0cyI6IlvhuqzGpcalxprhur3hur3hur0gxqXDtsO2w7bGjMOnxIPEg8SDxaHhua/FoV0iLCJHb29nbGUgcG9kY2FzdHMiOiJbx6bDtsO2w7bDtsO2w7bEo8aa4bq94bq94bq9IMalw7bDtsO2xozDp8SDxIPEg8Wh4bmvxaFdIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlvFoMalw7bDtsO24bmvw6zDrMOsxpLDvcO9w70gxqXDtsO2w7bGjMOnxIPEg8SDxaHhua/FoV0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTdWJzY3JpYmUgdG8gb3VyIHBvZGNhc3QiOiJbxaDHmseax5rDn8Whw6fFmcOsw6zDrMOf4bq94bq94bq9IOG5r8O2w7bDtiDDtsO2w7bHmseax5rFmSDGpcO2w7bDtsaMw6fEg8SDxIPFoeG5r10iLCJBcHBsZSBwb2RjYXN0cyI6IlvhuqzGpcalxprhur3hur3hur0gxqXDtsO2w7bGjMOnxIPEg8SDxaHhua/FoV0iLCJHb29nbGUgcG9kY2FzdHMiOiJbx6bDtsO2w7bDtsO2w7bEo8aa4bq94bq94bq9IMalw7bDtsO2xozDp8SDxIPEg8Wh4bmvxaFdIiwiU3BvdGlmeSBwb2RjYXN0cyI6IlvFoMalw7bDtsO24bmvw6zDrMOsxpLDvcO9w70gxqXDtsO2w7bGjMOnxIPEg8SDxaHhua/FoV0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    