import type {
  ImageBlockFragment,
  ImageBlockGroupFragment,
} from '@seek/cmsu-cms-connect';
import { Box } from 'braid-design-system';
import React from 'react';
import { InlineImageBlock } from './InlineImageBlock';
import { RegularImageBlock } from './RegularImageBlock';

type Props = Pick<
  ImageBlockFragment,
  'heading' | 'image' | 'paragraph' | 'cButtonLink' | 'cTextLink'
> &
  Pick<
    ImageBlockGroupFragment,
    'inlineBlockItem' | 'blockBackground' | 'imageAlignment'
  >;

const ImageBlock = ({
  image,
  heading,
  paragraph,
  inlineBlockItem,
  blockBackground,
  imageAlignment,
  cButtonLink,
  cTextLink,
}: Props) => (
  <Box data-testid="ImageBlockTestId" height="full">
    {inlineBlockItem ? (
      <InlineImageBlock
        imageAlignment={imageAlignment}
        heading={heading}
        image={image}
        paragraph={paragraph}
        blockBackground={blockBackground}
        cButtonLink={cButtonLink}
        cTextLink={cTextLink}
      />
    ) : (
      <RegularImageBlock
        heading={heading}
        image={image}
        paragraph={paragraph}
        cButtonLink={cButtonLink}
        cTextLink={cTextLink}
      />
    )}
  </Box>
);
export { ImageBlock };
