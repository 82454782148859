import {
  ApolloClient,
  InMemoryCache,
  from,
  createHttpLink,
} from '@apollo/client';
import { hydrateRoot } from 'react-dom/client';
import { createBrowserRouter } from 'react-router-dom';
import { loadableReady } from 'sku/@loadable/component';
import { RouterProvider } from 'react-router';
import { makeRoutes } from '../routes';
import {
  deHyphen,
  initTealiumTracker,
} from './shared/utils/tealiumTracker/tealiumAdapterHelper';
import { setupDatadogRum } from 'src/client/shared/utils/datadog';

loadableReady(async () => {
  const container = document.getElementById('app');

  if (!container) {
    throw Error('App container (#app) not found.');
  }

  const config = window.__CONFIG_STATE__;

  const { section, brand, country, language, zone, environment } = config;
  const datadogRum = setupDatadogRum(environment, config);

  initTealiumTracker({
    siteSection: deHyphen(section),
    brand,
    siteCountry: country,
    siteLanguage: language,
    zone,
  });

  datadogRum.startSessionReplayRecording();

  const client = new ApolloClient({
    cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
    headers: {
      'hyg-stale-if-error': '86400',
      'hyg-stale-while-revalidate': '300',
    },
    link: from([
      createHttpLink({
        uri: config.hygraphContentApi,
      }),
    ]),
  });

  const router = createBrowserRouter(makeRoutes({ client, config }));

  hydrateRoot(container, <RouterProvider router={router} />);
});
