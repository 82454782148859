const ANZ_LOCALE = ['en_AU', 'en_NZ'];
export const isAboutSectionSearch = (section: string, locale: string) =>
  (section === 'about' || section === 'about-test') &&
  ANZ_LOCALE.includes(locale);

export const getSearchPathBy = (section: string) => {
  switch (section) {
    case 'about':
      return 'about/search';
    case 'about-test':
      return 'about-test/search';
    default:
      return 'article-search';
  }
};
