import {
  ApacCandidateHeader,
  ApacCandidateFooter,
  ApacCandidateHeaderFooterLinkProvider,
} from '@seek/apac-candidate-header-footer';
import { useMelwaysLink } from '@seek/melways-react';
import type { Country } from '@seek/melways-sites';

import { useAuth, useConfig } from 'src/client/shared/hooks';
import { linkRenderer } from '../modules/renderers/linkRenderer';

interface PageProps {
  children: React.ReactNode;
  layout?: 'full' | 'basic' | 'none';
}

export const CandidateLayout = ({ children, layout = 'full' }: PageProps) => {
  const { country, language, route, section } = useConfig();
  const { authenticationStatus, caAccountContext } = useAuth();

  const urlResolver = useMelwaysLink();
  const path = route.split(`/${country}`).pop() || route;

  const getLanguageSwitcher = (c: Country) => {
    if (c !== 'id' && c !== 'th') {
      return undefined;
    }

    return {
      en: {
        url: urlResolver({ language: 'en', path }),
      },

      [country]: {
        url: urlResolver({ language: c, path }),
      },
    };
  };

  return (
    <ApacCandidateHeaderFooterLinkProvider value={linkRenderer}>
      {layout !== 'none' && (
        <ApacCandidateHeader
          activePrimaryTab={section === 'profile' ? section : undefined}
          authenticationStatus={authenticationStatus}
          leanHeader={layout === 'basic' && true}
          language={language}
          languageSwitcher={getLanguageSwitcher(country)}
          userProfile={caAccountContext}
        />
      )}

      {children}

      <ApacCandidateFooter
        authenticationStatus={authenticationStatus}
        language={language}
      />
    </ApacCandidateHeaderFooterLinkProvider>
  );
};
