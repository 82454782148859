import type { CardPrimaryFragment } from '@seek/cmsu-cms-connect';
import { Bleed, Box, Stack, Badge } from 'braid-design-system';
import { vars } from 'braid-design-system/css';
import { Heading } from '../Heading/Heading';
import { Paragraph } from '../Paragraph/Paragraph';
import { ActionGroup } from '../ActionGroup/ActionGroup';

import { headerImageStyle } from './styles.css';

type Props = Pick<
  CardPrimaryFragment,
  'badge' | 'paragraph' | 'actionGroup'
> & {
  heading?: React.ComponentProps<typeof Heading> | null;
  image?: {
    url: string;
    alt?: string | null;
  } | null;
};

const Card = ({ heading, paragraph, actionGroup, image, badge }: Props) => (
  <Box
    borderRadius="standard"
    padding="gutter"
    overflow="hidden"
    height="full"
    display="flex"
    flexDirection="column"
    justifyContent="spaceBetween"
    style={{ border: `1px solid ${vars.borderColor.neutralLight}` }}
    textAlign="left"
    background="surface"
    data-testid="CardTestId"
  >
    <Stack space="gutter">
      {image ? (
        <Bleed horizontal="gutter" top="gutter">
          <img
            src={image.url}
            alt={image.alt || undefined}
            className={headerImageStyle}
          />
        </Bleed>
      ) : null}

      <Stack space="small">
        {badge?.text ? <Badge tone={badge?.tone}>{badge.text}</Badge> : null}
        {heading && (
          <Box marginBottom="small">
            <Heading {...heading} />
          </Box>
        )}
        {paragraph && (
          <Paragraph content={paragraph.Paragraph_text?.raw} {...paragraph} />
        )}
      </Stack>
    </Stack>
    {actionGroup && (
      <Box paddingTop="large">
        <ActionGroup {...actionGroup} />
      </Box>
    )}
  </Box>
);

export { Card };
