import { useEffect, useState } from 'react';
import { useAuth, useConfig } from 'src/client/shared/hooks';
import {
  CONTENT_ELEMENT_PRESSED_EVENT,
  CMS_PAGE_DISPLAYED_EVENT,
  SOCIAL_LINK_IDS,
} from 'src/client/shared/utils/tealiumTracker/constants';
import {
  deHyphen,
  getLoggedInStatus,
  getPageTrackingData,
  setPreviousSiteSection,
  trackEvent,
} from 'src/client/shared/utils/tealiumTracker/tealiumAdapterHelper';
import type { TrackingData } from '../utils/tealiumTracker/TealiumAdapterTypes';
import type { CoreAnalyticsData } from '@seek/cmsu-analytics';

const trackingHandler = (e: MouseEvent) => {
  e.preventDefault();
  const currentTarget = e.currentTarget as HTMLButtonElement;
  const elementAction = currentTarget.ariaLabel?.toLocaleLowerCase() || '';

  trackEvent(CONTENT_ELEMENT_PRESSED_EVENT, {
    elementDesign: 'button',
    elementAction,
    elementText: elementAction,
  });
};

const usePageTracking = (
  currentPage: string,
  slug?: string,
  previousSiteSection?: string,
) => {
  const { authenticationStatus, loginId } = useAuth();
  const { section } = useConfig();

  useEffect(() => {
    SOCIAL_LINK_IDS.forEach((id) => {
      const buttonId = document.getElementById(id);
      buttonId?.addEventListener('click', trackingHandler);
    });
    const { isPending } = getLoggedInStatus(authenticationStatus);

    if (!isPending) {
      const hasSection = section !== 'page';
      const sectionData: Record<string, string> = {
        siteSection: hasSection ? deHyphen(section) : 'other',
      };

      setPreviousSiteSection(previousSiteSection ?? currentPage);

      const isLoggedIn = authenticationStatus === 'authenticated';

      trackEvent(CMS_PAGE_DISPLAYED_EVENT, {
        currentPage,
        ...sectionData,
        isLoggedIn,
        ...(isLoggedIn ? { loginId } : {}),
      });
    }
    return () => {
      SOCIAL_LINK_IDS.forEach((id) => {
        const buttonId = document.getElementById(id);
        buttonId?.removeEventListener('click', trackingHandler);
      });
    };
  }, [
    authenticationStatus,
    loginId,
    currentPage,
    slug,
    section,
    previousSiteSection,
  ]);
};

const usePageTrackingData = (currentPage: string): CoreAnalyticsData => {
  const { authenticationStatus } = useAuth();
  const { isLoggedIn } = getLoggedInStatus(authenticationStatus);
  const { section } = useConfig();
  const hasSection = section !== 'page';
  const sectionData: Record<string, string> = {
    siteSection: hasSection ? deHyphen(section) : 'other',
  };

  const [pageTrackingData, setPageTrackingData] = useState<
    Partial<TrackingData>
  >({
    brand: '',
    isLoggedIn: false,
    loginId: undefined,
    siteCountry: '',
    siteLanguage: '',
    zone: '',
    currentPage: '',
    siteSection: '',
  });

  useEffect(() => {
    const trackingData = getPageTrackingData();
    setPageTrackingData(trackingData);
  }, [authenticationStatus, currentPage, section]);

  return {
    ...pageTrackingData,
    ...sectionData,
    isLoggedIn,
    currentPage,
  } as CoreAnalyticsData;
};

export { usePageTracking, usePageTrackingData };
