import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useConfig } from './useConfig';
import { SEARCH_API_ENDPOINT } from '../../../config';
import { convertMelwaysLocale } from '../../../helpers/convertMelwaysLocale';
import { useMelwaysLink } from '@seek/melways-react';

export type SearchResultsResponse = {
  total: number;
  publishedAt: string;
  data: Array<{
    id: string;
    title: string;
    slug: string;
    categoryName: string;
    sectionName: string;
    featureImage: {
      width: number;
      height: number;
      url: string;
      alt: string;
    } | null;
  }>;
};

export const PAGE_LIMIT = 9;
const ANZ_LOCALE = ['en_AU', 'en_NZ'];

export const useHandleNavigateToFirstPage = (): {
  navigateToFirstPage: () => void;
} => {
  const { term } = useParams();
  const { language, section, melwaysLocale } = useConfig();
  const urlResolver = useMelwaysLink();
  const decodedTerm = decodeURIComponent(term || '');
  const trimmedTerm = decodedTerm.trim();
  const navigate = useNavigate();
  const location = useLocation();
  const locale = convertMelwaysLocale(melwaysLocale);

  const getNavigationSection = () => {
    switch (section) {
      case 'about':
        return 'about/search';
      case 'about-test':
        return 'about-test/search';
      default:
        return 'article-search';
    }
  };

  const navigateToFirstPage = useCallback<() => void>(() => {
    const searchSection = !ANZ_LOCALE.includes(locale)
      ? 'article-search'
      : getNavigationSection();
    const newNavigationPath = `/${searchSection}/${trimmedTerm}/1`;
    if (newNavigationPath !== location.pathname) {
      navigate(
        urlResolver({
          language,
          path: `/${searchSection}/${trimmedTerm}/1`,
        }),
      );
    }
    // Skipping urlResolver as dependency to avoid reRenders loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trimmedTerm, location.pathname, navigate, language]);

  return {
    navigateToFirstPage,
  };
};

export const useHandleSearch = (): {
  handleSearch: (term: string) => void;
  searchedTerm: string;
  isSearchInProgress: boolean;
  searchResults: SearchResultsResponse | undefined;
  setSearchedTerm: (term: string) => void;
} => {
  const { term, pageNumber } = useParams();
  const decodedTerm = decodeURIComponent(term || '');
  const trimmedTerm = decodedTerm.trim();
  const { melwaysLocale, section } = useConfig();
  const [searchResults, setSearchResults] = useState<
    SearchResultsResponse | undefined
  >(undefined);
  const [searchedTerm, setSearchedTerm] = useState(trimmedTerm || '');
  const [isSearchInProgress, setIsSearchInProgress] = useState(true);
  const locale = convertMelwaysLocale(melwaysLocale);
  const { navigateToFirstPage } = useHandleNavigateToFirstPage();
  const searchSection =
    (section === 'about' || section === 'about-test') &&
    ANZ_LOCALE.includes(locale)
      ? 'news'
      : 'hami';

  const handleSearch = useCallback<(term: string) => void>(
    async (termFromSearch: string) => {
      setIsSearchInProgress(true);
      const response = await fetch(
        `${SEARCH_API_ENDPOINT}/api/cms-search?page=${
          Number(pageNumber ?? 1) - 1
        }&limit=${PAGE_LIMIT}&q=${encodeURIComponent(
          termFromSearch.trim(),
        )}&locale=${locale}&section=${searchSection}`,
      ).catch(() => {
        // If the API call fails, reset the state
        setIsSearchInProgress(false);
        setSearchedTerm(termFromSearch);
        setSearchResults(undefined);
      });

      if (response?.ok) {
        const payload: SearchResultsResponse = await response.json();

        // If the API call returns an empty array it means that the page number does not exist.
        // In this case, redirect to the first page or else it causes <Pagination /> component to fail
        if (payload.data.length === 0) {
          navigateToFirstPage();
          setSearchResults(undefined);
        } else {
          setSearchResults(payload);
        }

        setSearchedTerm(termFromSearch);
        setIsSearchInProgress(false);
      }
    },
    [locale, navigateToFirstPage, pageNumber, searchSection],
  );

  return {
    handleSearch,
    searchedTerm,
    isSearchInProgress,
    searchResults,
    setSearchedTerm,
  };
};

export const useHandleSearchResultsOnMount = ({
  handleSearch,
}: {
  handleSearch: (term: string) => void;
}) => {
  const { term, pageNumber } = useParams();
  const decodedTerm = decodeURIComponent(term || '');
  const trimmedTerm = decodedTerm.trim();
  const { navigateToFirstPage } = useHandleNavigateToFirstPage();

  useEffect(() => {
    // <Pagination /> component does not support 0
    const isPageNumberNotValid =
      Boolean(pageNumber) && Number(pageNumber) === 0;

    // If page number is not valid, redirect to first page on mount
    if (isPageNumberNotValid) {
      return navigateToFirstPage();
    }

    // If page number is valid and there is a search term & page number call handle search
    if (trimmedTerm && pageNumber) {
      handleSearch(trimmedTerm);
    }
  }, [pageNumber, trimmedTerm, handleSearch, navigateToFirstPage]);
};
