import { useState } from 'react';
import { Box } from 'braid-design-system';

import { Builder, type SelectedQuestion } from './APAC_Builder';

export const PracticeInterviewBuilder = () => {
  const [activeList, setActiveList] = useState<SelectedQuestion[]>([]);

  return (
    <Box
      padding="xsmall"
      id="interviewBuilder"
      data-testid="InterviewBuilderTestId"
    >
      <Builder activeList={activeList} setActiveList={setActiveList} />
    </Box>
  );
};
