import type * as React from 'react';
import { FastestGrowingSalaries } from './FastestGrowingSalaries/FastestGrowingSalaries';
import { HighestPayingJobs } from './HighestPayingJobs/HighestPayingJobs';

export type CustomContentTitle =
  | 'FASTEST_GROWING_SALARIES'
  | 'HIGHEST_PAYING_JOBS';

interface Props {
  title: string;
}

export const CustomContent = ({ title }: Props) => {
  const content = CustomContentMapping[title];
  return <>{content}</>;
};

export const CustomContentMapping: { [key: string]: React.ReactNode } = {
  FASTEST_GROWING_SALARIES: <FastestGrowingSalaries />,
  HIGHEST_PAYING_JOBS: <HighestPayingJobs />,
};
