import type { DocumentListFragment } from '@seek/cmsu-cms-connect';
import { Stack } from 'braid-design-system';
import { DocumentListItem } from './DocumentListItem';

type DocumentListProps = DocumentListFragment;

export const DocumentList = ({ icon, items }: DocumentListProps) => (
  <Stack space="xsmall">
    {items.map((item) => (
      <DocumentListItem key={item.id} {...item} overrideIcon={icon} />
    ))}
  </Stack>
);
