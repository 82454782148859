import type { Dividend } from '@seek/cmsu-components/src/modules/InvestorInformation/Dividend/Dividend';
import type { Shareprice } from '@seek/cmsu-components/src/modules/InvestorInformation/SharePrice/SharePrice';
import type { AsxAnnouncement } from '@seek/cmsu-components/src/modules/InvestorInformation/AsxAnnouncements/AsxAnnouncements';
const loadDividends = async (): Promise<Dividend[] | undefined> => {
  const response = await fetch(
    'https://wcsecure.weblink.com.au/clients/seek/dividendjson.aspx',
  );

  if (response.status === 200)
    return (await response.json()).data as Dividend[];

  return undefined;
};

const loadSharePrice = async (): Promise<Shareprice | undefined> => {
  const response = await fetch(
    'https://wcsecure.weblink.com.au/clients/seek/quotejson.aspx',
  );

  if (response.status === 200) return (await response.json())[0] as Shareprice;
  return undefined;
};

const latestAsxAnnouncementsUrl =
  'https://wcsecure.weblink.com.au/clients/seek/headlinejson.aspx';

type LoadLatestAsxAnnouncementsProps = {
  pageNumber?: number | string;
};
const loadLatestAsxAnnouncements = async ({
  pageNumber,
}: LoadLatestAsxAnnouncementsProps): Promise<AsxAnnouncement | undefined> => {
  const url = new URL(latestAsxAnnouncementsUrl);
  // IMPORTANT: page number is 1-indexed
  if (pageNumber && Number(pageNumber) > 0) {
    url.searchParams.set('pageNumber', pageNumber.toString());
  }
  const response = await fetch(url.toString());

  if (response.status === 200)
    return (await response.json()).WebLinkHeadlineData as AsxAnnouncement;
  return undefined;
};

export const weblinkLoader = {
  loadDividends,
  loadSharePrice,
  loadLatestAsxAnnouncements,
};

// include this because it can be used for pagination
export const weblinkUrl = {
  latestAsxAnnouncement: latestAsxAnnouncementsUrl,
};
