import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';
import { SEARCH_API_ENDPOINT } from 'src/config';
import { useConfig } from './useConfig';
import { useMelwaysLink } from '@seek/melways-react';
import { isAboutSectionSearch } from '@seek/cmsu-components/src/utils/searchHelper';

export const useFetchSuggestions = (locale: string, term?: string) => {
  const [titleSuggestions, setTitleSuggestions] = useState<
    { text: string; value: string }[]
  >([]);
  const { language, section } = useConfig();
  const urlResolver = useMelwaysLink();
  const searchSection = isAboutSectionSearch(section, locale) ? 'news' : 'hami';

  useEffect(() => {
    const fetchSuggestions = debounce(async (ct: string) => {
      const response = await fetch(
        `${SEARCH_API_ENDPOINT}/api/cms-search/autocomplete?locale=${locale}&q=${encodeURIComponent(
          ct.trim(),
        )}&section=${searchSection}`,
      );
      if (response.ok) {
        const suggestions: {
          titleSuggestions: string;
          slug: string;
          sectionName: string;
          parentSectionName?: string;
        }[] = await response.json();
        setTitleSuggestions(
          suggestions.map((s) => ({
            text: s.titleSuggestions,
            value: urlResolver({
              language,
              path: `/${getSectionPath(
                s.sectionName,
                s.parentSectionName,
              )}/article/${s.slug}`,
            }),
          })),
        );
      }
    }, 500);
    if (locale && term) {
      fetchSuggestions(term);
    }
    // Skipping urlResolver as dependency to avoid reRenders loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, locale, term]);
  return titleSuggestions;
};

const getSectionPath = (sectionName: string, parentSectionName?: string) =>
  parentSectionName && parentSectionName.length > 0
    ? `${parentSectionName}/${sectionName}`
    : sectionName;
