import React from 'react';
import { vars } from 'braid-design-system/css';
import {
  AccordionItem,
  Bleed,
  Box,
  Button,
  ButtonIcon,
  Column,
  Columns,
  Heading,
  IconAdd,
  IconClear,
  Inline,
  Text,
  Textarea,
  useResponsiveValue,
} from 'braid-design-system';
import { ImageUI } from './APAC_Image';

import { ADD_QUESTION, type SelectedQuestion } from './APAC_Builder';
import type { TopicQuestion } from './questions';
import AddQuestion from './assets/add-question.png';
import Behavioural from './assets/behavioural.png';
import Motivation from './assets/motivation.png';
import Situational from './assets/situational.png';
import SkillsBased from './assets/skills-based.png';

const iconMapping: Record<string, string> = {
  motivation: Motivation,
  behavioural: Behavioural,
  situational: Situational,
  'skills-based': SkillsBased,
  'add-question': AddQuestion,
};

interface QuestionListProps extends TopicQuestion {
  questionKey: string;
  activeList: SelectedQuestion[];
  addActiveItem: (item: SelectedQuestion) => void;
  removeActiveItem: (item: SelectedQuestion) => void;
  addCustomQuestion?: (question: string) => void;
}

export const QuestionList = ({
  questionKey,
  icon,
  name,
  description,
  questions,
  activeList,
  addActiveItem,
  removeActiveItem,
  addCustomQuestion,
}: QuestionListProps) => {
  const [customQuestion, setCustomQuestion] = React.useState<string>('');
  const responsiveValue = useResponsiveValue();
  const isMobile = responsiveValue({
    mobile: true,
    tablet: false,
  });

  const isQuestionSelected = (question: string) =>
    activeList.findIndex(
      (q) => q.question === question && q.parent === questionKey,
    ) !== -1;

  const QuestionItem = ({ question }: { question: string }) =>
    isQuestionSelected(question) && !Boolean(isMobile) ? null : (
      <Box
        key={question.slice(0, 40)}
        cursor="pointer"
        style={{
          borderBottom: `1px solid ${vars.borderColor.neutralLight}`,
        }}
        paddingY="medium"
        onClick={() => {
          if (isQuestionSelected(question)) {
            removeActiveItem({ parent: questionKey, question });
          } else {
            addActiveItem({ parent: questionKey, question });
          }
        }}
      >
        <Columns space="small" alignY="center" reverse={Boolean(isMobile)}>
          <Column>
            <Text>{question}</Text>
          </Column>
          <Column width="content">
            {isQuestionSelected(question) ? (
              <ButtonIcon
                id="remove-question"
                label="remove"
                icon={<IconClear />}
              />
            ) : (
              <ButtonIcon id="add-question" label="add" icon={<IconAdd />} />
            )}
          </Column>
        </Columns>
      </Box>
    );

  return (
    <Box position="relative" paddingLeft="xlarge">
      <Box
        position="absolute"
        style={{ width: '40px', height: '40px', top: '-14px' }}
        left={0}
      >
        <ImageUI imageUrl={iconMapping[icon]} alt={`${icon}-icon`} />
      </Box>
      {questionKey === ADD_QUESTION ? (
        <>
          <Box marginY="large">
            <Heading level="4">{name}</Heading>
          </Box>
          <Bleed left="xlarge">
            {questions.map((question, index) => (
              <QuestionItem key={index} question={question} />
            ))}
            <Box marginTop="large">
              <Textarea
                id="ADD_QUESTION"
                label=""
                value={customQuestion}
                onChange={(e) => setCustomQuestion(e.currentTarget.value)}
                placeholder="Type your question here and add to your list"
              />
            </Box>
            <Box marginTop="large">
              <Inline space="small">
                <Button
                  onClick={() => {
                    if (customQuestion && addCustomQuestion) {
                      addCustomQuestion(customQuestion);
                      setCustomQuestion('');
                    }
                  }}
                >
                  Add
                </Button>
              </Inline>
            </Box>
          </Bleed>
        </>
      ) : (
        <AccordionItem label={name} id={questionKey}>
          <Bleed left="xlarge">
            {description ? (
              <Box marginY="medium">
                <Text tone="secondary">{description}</Text>
              </Box>
            ) : null}
            {questions.map((question, index) => (
              <QuestionItem key={index} question={question} />
            ))}
          </Bleed>
        </AccordionItem>
      )}
    </Box>
  );
};
